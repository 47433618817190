// i18next-extract-mark-ns-start monei-pay

import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Images
import monei_pay_qr_en from 'images/monei_pay_qr_en.png';
import monei_pay_qr_es from 'images/monei_pay_qr_es.png';
import {Flex} from 'components/Flex';
import React from 'react';
import {DownloadOnAppleStore} from 'components/DownloadOnAppleStore';
import {DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import Bold from 'components/Bold';
import {AnchorLink} from 'components/AnchorLink';
import {LanguageEnum} from '../../../locales/types';
import {MoneiVideoSection} from 'components/landings/MoneiVideoSection';
import {CompatibleWith} from 'components/monei-pay/CompatibleWith';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {StyledDiv} from 'components/StyledSx';
import {QrGroup} from 'components/monei-pay/QrGroup';
import {HorizontalScreenshots} from 'components/monei-pay/HorizontalScreenshots';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';
import {InternalPageLink} from 'components/links/Pages';
import {SupportLink} from 'components/links/Support';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import {ButtonLink} from 'components/Button';
import styled from 'styled-components';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const MoneiPay: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const scanToPay = isSpanish ? monei_pay_qr_es : monei_pay_qr_en;
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const ReviewsBackground = styled.div`
    position: relative;
    margin-top: 100px;

    &:before {
      display: block;
      content: '';
      z-index: -1;
      height: 70%;
      position: absolute;
      transform: skew(0, -9deg);
      left: 0;
      right: 0;
      bottom: 0;
      top: 20%;
      background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
    }
  `;

  return (
    <>
      <SEO
        title={t('Hardware Free POS System')}
        description={t(
          'Avoid a bulky and unreliable POS and save on transaction fees by accepting payments from' +
            ' your phone. Use a hardware free POS system. Get MONEI Pay ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Get a simple and hardware free POS system</Trans>
              </SectionHeader>
              <Trans parent="p">
                Tired of dealing with high prices, short battery life, frequent network issues, and
                not having enough devices for your staff? Ditch expensive POS systems to quickly and
                securely accept payments from your phone or tablet. <Bold>Get MONEI Pay</Bold>, the
                best hardware free POS system.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay Now</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {md: '20px'}}}>
        <Section centered sx={{paddingTop: '20px'}}>
          <SectionImage
            style={{width: '455px'}}
            mobileWidth={370}
            src={scanToPay}
            alt="Scan to Pay"
            title="Scan to Pay"
            className="hide-on-mobile"
          />
          <div>
            <SectionHeader underline style={{marginTop: '10px'}}>
              <Trans>Use a mobile payment app to accept payments without a traditional POS</Trans>
            </SectionHeader>
            <Trans parent="p">
              The best POS system for your store or restaurant is not a traditional POS. Skip the
              bulky hardware and use a simple, secure, and cheap POS system to charge customers from
              any mobile device using digital{' '}
              <InternalPageLink slug="monei-pay/qr">QR codes</InternalPageLink> or payment requests.
            </Trans>
            <Trans parent="p">Unlike traditional POS systems, with MONEI Pay, you can:</Trans>
            <List>
              <Trans parent="li">
                Save money on hardware and{' '}
                <InternalPageLink slug="pricing">transaction fees</InternalPageLink> with a POS
                system that’s 50% cheaper than traditional providers{' '}
              </Trans>
              <Trans parent="li">
                Accept{' '}
                <InternalPageLink slug="payment-methods">multiple payment methods</InternalPageLink>
                :{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit cards
                </InternalPageLink>
                , <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> (in Spain){' '}
              </Trans>
              <Trans parent="li">
                Provide a <BlogLink slug="frictionless-payments">frictionless payment</BlogLink>{' '}
                flow
              </Trans>
              <Trans parent="li">
                Equip your entire staff with a{' '}
                <BlogLink slug="benefits-of-mobile-payments">mobile payment</BlogLink> app{' '}
              </Trans>
              <Trans parent="li">
                Use an agile{' '}
                <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
                  POS system in your restaurant or bar
                </InternalPageLink>
              </Trans>
            </List>
            <Trans parent="p">👇 Download your mobile payment app today.</Trans>
            <Flex>
              <div style={{marginRight: '16px'}}>
                <DownloadOnAppleStore />
              </div>
              <DownloadOnGooglePlay />
            </Flex>
          </div>
        </Section>
      </Content>
      <Content sx={{maxWidth: '991px'}}>
        <Section textAlign="center" column centered sx={{width: '100%', gap: '12px'}}>
          <SectionHeader underline>
            <Trans style={{fontSize: '2rem'}}>
              How does <Bold>MONEI Pay</Bold> work?
            </Trans>
          </SectionHeader>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            <SupportLink slug="/articles/4417692136977">MONEI Pay</SupportLink> is a hardware free
            POS system that lets you accept payments from anywhere{' '}
            <SupportLink slug="/sections/360005899978">using digital QR codes</SupportLink> or send
            payment requests directly to your customer's smartphone.
          </Trans>
          <Trans parent="p">
            <Bold>Accept QR payments with MONEI Pay</Bold>
          </Trans>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            Open the MONEI Pay app on your mobile device and enter the payment amount. A digital QR
            payment code will appear on the screen, then your customer can use their smartphone to
            scan the QR code, choose their preferred payment method, and complete the purchase.
          </Trans>
          <Trans parent="p">
            <Bold>Send payment requests with MONEI Pay</Bold>
          </Trans>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            After you enter the payment amount in the MONEI Pay app, click the “Send payment
            request” button and enter your customer’s mobile phone number. If their phone number is
            registered in Bizum, the customer will get a push notification to confirm the payment
            directly in the banking app, otherwise, they will receive a link to pay via SMS.
          </Trans>
          <SignUpButton variant="light">
            <Trans>Try MONEI Pay</Trans>
          </SignUpButton>
        </Section>
      </Content>
      <Content fullWidth>
        <HorizontalScreenshots />
      </Content>

      <Content sx={{maxWidth: '991px'}}>
        <Section textAlign="center" column centered sx={{width: '100%', gap: '12px'}}>
          <SectionHeader underline>
            <Trans>What types of businesses use MONEI Pay for mobile payments?</Trans>
          </SectionHeader>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            Essentially any business that wants to accept physical payments from their phone or
            tablet can use MONEI Pay. Here are some of the most common use cases:
          </Trans>
          <Trans parent="p">
            <Bold>E-commerce and brick-and-mortar shops</Bold>
          </Trans>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            Whether you occasionally take your online business offline, have a boutique, or run a
            multi-location brick-and-mortar business, speed up your{' '}
            <InternalPageLink slug="monei-pay/retail-POS-system">retail payments</InternalPageLink>,
            boost the customer experience, and track your omnichannel sales with MONEI Pay.
          </Trans>
          <Trans parent="p">
            <Bold>Hospitality</Bold>
          </Trans>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
              Mobile payments for restaurants
            </InternalPageLink>{' '}
            offer more flexibility than traditional POS systems, even for events and food trucks.
          </Trans>
          <Trans parent="p">
            <Bold>Freelancers</Bold>
          </Trans>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            Whether you’re a web designer, a nanny, an accountant, or an artist, MONEI Pay is the
            most flexible{' '}
            <InternalPageLink slug="monei-pay/pos-for-freelancers">
              POS for freelancers
            </InternalPageLink>
            .
          </Trans>
          <Trans parent="p">
            <Bold>Taxis</Bold>
          </Trans>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            Accepting <BlogLink slug="taxi-card-payments">taxi card payments</BlogLink> is now
            required in most cities. Replace your old dataphone and save time and money by using
            your smartphone as a{' '}
            <InternalPageLink slug="monei-pay/pos-systems-for-taxis">
              taxi POS system
            </InternalPageLink>
            .
          </Trans>
          <Trans parent="p">
            <Bold>Hair and beauty salons</Bold>
          </Trans>
          <Trans parent="p" style={{marginBottom: '16px'}}>
            Simplify your daily work by using the best alternative to a{' '}
            <InternalPageLink slug="monei-pay/pos-systems-for-hair-salon">
              POS system for hair salons
            </InternalPageLink>
            . Choose a more convenient, affordable, and reliable option — try MONEI Pay.
          </Trans>
          <Trans>
            <Bold>
              Have questions about MONEI Pay?{' '}
              <SupportLink slug="/requests/new">Contact us today!</SupportLink>
            </Bold>
          </Trans>
        </Section>
      </Content>
      <Content>
        <Section reverseOnMobile style={{alignItems: 'center', padding: '0'}}>
          <QrGroup />
          <div>
            <SectionHeader underline>
              <Trans>Start accepting payments from your phone or tablet </Trans>
            </SectionHeader>
            <Trans parent="p">
              <Bold>Setting up your MONEI Pay account is simple.</Bold> Create your account, then
              download the{' '}
              <AnchorLink href="https://play.google.com/store/apps/details?id=com.monei.moneibusiness">
                Android
              </AnchorLink>{' '}
              or{' '}
              <AnchorLink href="https://apps.apple.com/app/monei-pos/id1624647668">iOS</AnchorLink>{' '}
              mobile payment app on as many devices as you need to start generating digital QR codes
              and/or payment requests.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to test the payment experience.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Create an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '100px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a business platform or marketplace?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Whether you have a platform or marketplace for brands, restaurants, freelancers,
              taxis, or another industry, use MONEI Connect to integrate payments and help your
              users accept more payment methods online, in person, or on the go.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%'
            }}
            src={integrated_payments_header_image}
          />
        </GradientBox>
      </Content>
    </>
  );
};

export default MoneiPay;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "monei-pay"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
